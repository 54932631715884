import React, {  useEffect, useState } from 'react';
import mjml from 'mjml-browser';

import { BlockAvatarWrapper, EmailEditor, EmailEditorProvider, IEmailTemplate, Stack, useBlock, useEditorContext, } from 'easy-email-editor';

import { BlockManager, BasicType, AdvancedType, JsonToMjml, createCustomBlock, } from 'easy-email-core';
import { ExtensionProps, MjmlToJson, StandardLayout, BlockAttributeConfigurationManager, DefaultPageConfigPanel, TextField } from 'easy-email-extensions'; //SimpleLayout
import { useWindowSize } from 'react-use';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
import '@arco-themes/react-easy-email-theme/css/arco.css';



import { LoadingOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components'
// import { copy } from '../../utils/utils';

import { templateData } from '../../assets/js/templateData'
import { Button, message, Spin, Modal, Alert } from 'antd';
import { useLazyGetSingleEmailTemplateQuery, useLazyGetSiteInfoQuery, useSaveEmailTemplateMutation, useUpdateEmailTemplateMutation, useUploadImageMutation } from './mailerApiSlice';
import { useNavigate, useParams } from 'react-router-dom';
// import services from '../../services';

import imageCompression from 'browser-image-compression';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin } from '../auth/authSlice';
import { PHONE_TEMPLE_BASE_URL_IMG } from '../../config';
import LoadingComponentAntD from '../../components/loadingCompAntd/LoadingComponentAntD';
import { setSiteInfor } from '../../store/slice/siteInfoSlice';
// import CustomBlocksType from './CustomBlocks/Constants';
// import { MyFirstBlock } from './CustomBlocks/MyFirstBlock';

// import './CustomBlocks';
// import { MyFirstBlock } from './CustomBlocks/MyFirstBlock';

// const defaultCategories = [
//   {
//     label: 'Content',
//     active: true,
//     blocks: [
//       {
//         type: AdvancedType.TEXT,
//       },
//       {
//         type: AdvancedType.IMAGE,
//         payload: { attributes: { padding: '0px 0px 0px 0px' } },
//       },
//       {
//         type: AdvancedType.BUTTON,
//       },
//       {
//         type: AdvancedType.SOCIAL,
//       },
//       {
//         type: AdvancedType.DIVIDER,
//       },
//       {
//         type: AdvancedType.SPACER,
//       },
//       {
//         type: AdvancedType.HERO,
//       },
//       {
//         type: AdvancedType.WRAPPER,
//       },
//     ],
//   },
//   {
//     label: 'Layout',
//     active: true,
//     displayType: 'column',
//     blocks: [
//       {
//         title: '2 columns',
//         payload: [
//           ['50%', '50%'],
//           ['33%', '67%'],
//           ['67%', '33%'],
//           ['25%', '75%'],
//           ['75%', '25%'],
//         ],
//       },
//       {
//         title: '3 columns',
//         payload: [
//           ['33.33%', '33.33%', '33.33%'],
//           ['25%', '25%', '50%'],
//           ['50%', '25%', '25%'],
//         ],
//       },
//       {
//         title: '4 columns',
//         payload: [['25%', '25%', '25%', '25%']],
//       },
//     ],
//   },
//   // {
//   //   label: 'Custom Components',
//   //   active: true,
//   //   blocks: [
//   //     MyFirstBlock,
//   //   ]
//   // },
//   // {
//   //     label: 'Custom',
//   //     active: true,
//   //     displayType: 'custom',
//   //     blocks: [MyFirstBlock]
//   // },
//   {
//     label: 'Custom',
//     active: true,
//     displayType: 'custom',
//     blocks: [
//       <BlockAvatarWrapper type={CustomBlocksType.MY_FIRST_BLOCK}>
//         <div
//           style={{
//             position: 'relative',
//             border: '1px solid #ccc',
//             marginBottom: 20,
//             width: '80%',
//             marginLeft: 'auto',
//             marginRight: 'auto',
//           }}
//         >
//           <img
//             src={
//               'https://picsum.photos/200/300'
//             }
//             style={{
//               maxWidth: '100%',
//             }} alt=''
//           />
//           <div
//             style={{
//               position: 'absolute',
//               top: 0,
//               left: 0,
//               width: '100%',
//               height: '100%',
//               zIndex: 2,
//             }}
//           />
//         </div>
//       </BlockAvatarWrapper>,
//     ],
//   },
// ];

// import { PageConfigPanel } from 'easy-email-editor';
// import { TextField } from 'easy-email-extensions/AttributePanel/components/TextField';

// const CustomPageConfigPanel = () => {
//   const { focusBlock } = useBlock();
  
//   // Check if the focus block is a page block to avoid errors
//   if (focusBlock?.type !== 'page') return null;
//   return (
//     <div>
//       {/* Use TextField to change the label */}
//       <TextField
//         label="Signature"  // Change the label to 'Signature'
//         name="subject"     // The name property should remain 'subject' since it's part of the internal logic
//       />
//       {/* Include other configurations */}
//       <PageConfigPanel hideSubTitle /> 
//       {/* This will hide the subtitle field */}
//     </div>
//   );
// };



BlockAttributeConfigurationManager.add({
  [BasicType.PAGE]: () => 
  // <CustomPageConfigPanel />,
  <DefaultPageConfigPanel hideSubTitle />,
})


// const EmojiBlock = ({ onChange }) => {
//   const emojis = ['😀', '😂', '😍', '😎', '👍']; // Add your emojis here

//   return (
//     <div style={{ padding: '10px' }}>
//       {emojis.map((emoji, index) => (
//         <span
//           key={index}
//           style={{ fontSize: '24px', cursor: 'pointer', marginRight: '10px' }}
//           onClick={() => onChange(emoji)}
//         >
//           {emoji}
//         </span>
//       ))}
//     </div>
//   );
// };
// // Define a custom block with default content
// const emojiBlock = createCustomBlock({
//   name: 'emojiBlock',
//   type: BasicType.TEXT, // Since the emoji is a part of text, we use TEXT type
//   create: () => ({
//     type: 'emojiBlock',
//     data: {
//       value: { content: '' },
//     },
//     attributes: {},
//     children: [],
//   }),
//   render: ({ data, index, mode, onChange }) => (
//     <EmojiBlock
//       onChange={(emoji) => {
//         // Insert emoji in the text content
//         const content = data.value.content + emoji;
//         onChange({
//           ...data,
//           value: {
//             ...data.value,
//             content,
//           },
//         });
//       }}
//     />
//   ),
// });

// BlockManager.registerBlocks({
//   emojiBlock,
// });





const fontList = [
  'Verdana',
  'Lato',
  'Montserrat',
  'Great Vibes',
  'Roboto',
  'Roboto Condensed',
  'Comic Neue',
  'Nunito',
  'Poppins',
  'Playfair Display',
  'Rubik',
  'Qwitcher Grypen',
  'Lobster',
  'Zilla Slab',
  'Caveat',
  'Merriweather',
].map(item => ({ value: item, label: item }));
const initialValues = {
  subject: '',
  subTitle: '',
  content: BlockManager.getBlockByType(BasicType.PAGE).create({})
};

const pageBlock = BlockManager.getBlockByType(BasicType.PAGE);

function MailEditor(props) {
  const { user_token, tempId, auth, viewType } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [loaderContent, setLoaderContent] = useState("");
  const siteInfo = useSelector(state=>state.siteInfoStore?.info)
  // const { user_token, auth } = useParams();
  const dispatch = useDispatch();
  // const user = useSelector(state=> state.auth);

  useEffect(() => {
    if (user_token && auth && viewType) {
      dispatch(setLogin({
        user: null,
        token: user_token,
        auth,
        viewType
      }))
    }
  }, [user_token, auth,viewType]);
  // }

  const navigate = useNavigate();
  const [getSiteInfo] = useLazyGetSiteInfoQuery();

  const { width } = useWindowSize();
  const smallScene = width < 1400;
  const [myEmail, setMyEmail] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const [headersReady, setHeadersReady] = useState(false);
  const [instantUpdate, setInstantUpadate] = useState(false);
    // const { user_token, auth, viewType } = useParams();
  // const [savedEmail,setSaveEmail] = useState(initialValues);

  const [saveEmailTemplate] = useSaveEmailTemplateMutation();
  const [updateEmailTemplate] = useUpdateEmailTemplateMutation();
  const [getSingleEmailTemplate] = useLazyGetSingleEmailTemplateQuery();
  const storeAuth = useSelector(state=>state.auth)

  const [uploadImage] = useUploadImageMutation();
  useEffect(() => {
    if (user_token && auth && viewType) {
        dispatch(setLogin({
            user: null,
            token: user_token,
            auth,
            viewType
        }))
    }
}, [user_token, auth,viewType]);

useEffect(() => {
  if (storeAuth?.authBearer && storeAuth?.token) {
      // console.log("storeAuth",storeAuth)
      setHeadersReady(true);
  }
}, [storeAuth]);

  useEffect(() => {
    const fetchSiteInfo = async()=>{
        try {
            const {data,error} = await getSiteInfo();
            if (data) {
                // console.log("site-data::",data);
                dispatch(setSiteInfor(data))
                
            } 
            if (error) {
                console.log("site-data::",error);

            }
        } catch (error) {
            
        }

    }
    // console.log("before if->siteInfo::storeAuth")
    // console.log("headersReady",headersReady)
    if (!headersReady) {
      // console.log("if->siteInfo::storeAuth")
        fetchSiteInfo();
    }
}, [getSiteInfo, headersReady])

useEffect(() => {
  if (storeAuth?.authBearer && storeAuth?.token && siteInfo?.instant_email) {
    setMyEmail(prev => ({ ...prev, subTitle: siteInfo?.customer_name }))
  }
}, [siteInfo,storeAuth?.authBearer, storeAuth?.token])


  useEffect(() => {
    if (props.updating && tempId) {
      setLoaderContent("Loading");
      setLoading(true);
      (async () => {
        try {
          const resp = await getSingleEmailTemplate(tempId);
          if (resp.data) {
            if (resp.data.status) {
              let content = JSON.parse(resp?.data?.template?.template);
              
              const newContent = JSON.parse(content.content)
              setMyEmail(prevState => ({ ...prevState, subject: siteInfo?.instant_email?"": newContent?.subject, subTitle: newContent?.subTitle, content: pageBlock.create(newContent.content) }))
              setInstantUpadate(true);
            }
          }
          if (resp.error) {
            message.error(resp.error.message ?? resp.error.data.message ?? "Error getting Template!")

          }
        } catch (error) {

        } finally {
          setTimeout(() => {
            setLoading(false);
            setLoaderContent("")
          }, 3000);
        }

      })()
    }
  }, [tempId, props, getSingleEmailTemplate])

  useEffect(() => {
    if (storeAuth?.authBearer && storeAuth?.token && siteInfo?.instant_email && props.updating && instantUpdate) {
      setMyEmail(prev => ({ ...prev, subject: "",subTitle: siteInfo?.customer_name })) 
    }
  }, [siteInfo,storeAuth?.authBearer, storeAuth?.token,props.updating, instantUpdate])


  const defaultTemp = () => {
    setMyEmail(prevState => ({ ...prevState, content: pageBlock.create(templateData) }))
  }

  const successMsg = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
    });
  };

  const errorMsg = (message) => {
    messageApi.open({
      type: 'error',
      content: message,
    });
  };

  const resetTemplate = () => {
    // setMyEmail(prevState => ({ ...prevState, content: pageBlock.create({}) }))
    setMyEmail({...initialValues,subTitle: siteInfo?.instant_email? siteInfo?.customer_name:initialValues.subTitle})
  }

  function insertStyleIntoHtml(htmlContent, styleContent) {
    // Define the <style> tag you want to insert
    const styleTag = `<style>${styleContent}</style>`;
  
    // Find the position of the closing </head> tag
    const headCloseTagIndex = htmlContent.indexOf('</head>');
  
    if (headCloseTagIndex === -1) {
      // If there's no <head> tag, insert at the top of the document
      return styleTag + htmlContent;
    }
  
    // Insert the <style> tag before the closing </head> tag
    return (
      htmlContent.slice(0, headCloseTagIndex) + 
      styleTag + 
      htmlContent.slice(headCloseTagIndex)
    );
  }

  function insertLinksIntoHtml(htmlContent, linkTags) {
    // Find the position of the closing </head> tag
    const headCloseTagIndex = htmlContent.indexOf('</head>');
  
    // Join all link tags into one string
    const linksToInsert = linkTags.join('');
  
    if (headCloseTagIndex === -1) {
      // If there's no <head> tag, insert the links at the top of the document
      return linksToInsert + htmlContent;
    }
  
    // Insert the combined <link> tags before the closing </head> tag    
    return (
      htmlContent.slice(0, headCloseTagIndex) + 
      linksToInsert + 
      htmlContent.slice(headCloseTagIndex)
    );
  }



  const tempToHtml = (values) => {
    const html = mjml(JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content
    }), {
      beautify: true,
      validationLevel: 'soft',
    }).html;
    
    const updatedHtml = html.replace(/(white-space-collapse:\s*)preserve(;)/g, '$1$2');

    const fontLinks = [
      `<link href="https://fonts.googleapis.com/css2?family=Great+Vibes:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Caveat:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Lobster:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Qwitcher+Grypen:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Verdana:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`,
      `<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;800&display=swap" rel="stylesheet" type="text/css">`
    ];
    

    const finalHtml = insertLinksIntoHtml(updatedHtml, fontLinks);
  return finalHtml;
  }

  // const onCopyHtml = (values) => {
  //   const html = tempToHtml(values);
  //   // copy(html);
  //   console.log("tempToHtml::",html)

  // }

  // const onCopyHtml = (values: IEmailTemplate) => {
  //   const html = mjml(JsonToMjml({
  //     data: values.content,
  //     mode: 'production',
  //     context: values.content
  //   }), {
  //     beautify: true,
  //     validationLevel: 'soft',
  //   }).html;

  //   copy(html);
  //   message.success('Copied to pasteboard!')
  // };


  // const onImportMjml = () => { }
  // const onImportMjml = async () => {
  //   try {
  //     const [filename, data] = await importTemplate();
  //     setDownloadName(filename);
  //     setTemplate(data);
  //   } catch (error) {
  //     message.error('Invalid mjml file');
  //   }
  // };


  // const onExportMjml = () => { }
  // const onExportMjml = (IEmailTemplate) => {
  //   exportTemplate(
  //     downloadFileName,
  //     JsonToMjml({
  //       data: values.content,
  //       mode: 'production',
  //       context: values.content
  //     }))
  // };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        // color: '#fff'
      }}
      spin
    />
  );

// console.log("siteInfo::",siteInfo)
  const onSubmit = async (values, form) => {

    // console.log("form::",form)
    setSaving(prevSaving => !prevSaving);
    const html = tempToHtml(values);

    if (props.updating) {
      try {
        setLoading(true);
        setLoaderContent("Updating");
        let body = {
          name: values?.subject,
          subject: values?.subject,
          template: JSON.stringify(
            {
              template: html,
              content: JSON.stringify(values)
            }
          ),
        }
        const { data, error } = await updateEmailTemplate({ body, id: tempId });

        if (data) {
          if (data.status) {
            message.success(data.message);
            if (viewType==='false' && !siteInfo?.instant_email && !siteInfo?.email_signature) {
              navigate(`/${auth}/${user_token}/false`);
            }
            if(viewType==='true' && !siteInfo?.instant_email){
              setTimeout(() => {
                window.close();
              }, 2000);
            }
            if (viewType==='true' && siteInfo?.instant_email) {
              setTimeout(() => {
                window.location.replace(siteInfo?.back_url);
              }, 1000);
            }
            if (viewType==='false' && siteInfo?.email_signature) {
              setTimeout(() => {
                window.location.replace(siteInfo?.back_url);
              }, 1000);
            }
            if (siteInfo?.back_url_status) {
              setTimeout(() => {
                window.location.replace(siteInfo?.back_url);
              }, 500);
            }
            
          }
          if (!data.status) {
            message.error(data.message);
          }
        }
        if (error) {
          message.error(error.data.message);
        }
      } catch (error) {
        console.log("error::", error)
        // navigate(`/${auth}/${user_token}`);
        errorMsg(error?.data?.message)

      } finally {
        setSaving(!!saving);
        setTimeout(() => {
          setLoaderContent("")
          setLoading(false)
        }, 1000);
      }
    } else {
      try {
        setLoading(true);
        setLoaderContent("Saving")
        let body = {
          name: values?.subject,
          subject: values?.subject,
          template: JSON.stringify(
            {
              template: html,
              content: JSON.stringify(values)
            }
          ),
        }
  
        const { data, error } = await saveEmailTemplate(body);
  
        if (data) {
          if (data.status) {
            message.success(data.message);
            // console.log("success::", data);
            if (siteInfo?.back_url_status) {
              setTimeout(() => {
                window.location.replace(siteInfo?.back_url);
              }, 500);
            }
            if (siteInfo?.instant_email) {
              setTimeout(() => {
                window.location.replace(siteInfo?.back_url);
              }, 500);
            }
            else if (viewType==='false' && siteInfo?.email_signature) {
              setTimeout(() => {
                window.location.replace(siteInfo?.back_url);
              }, 500);
            }else{
              navigate(`/${auth}/${user_token}/false`);
            }
          }
          if (!data.status) {
            message.error(data.message);
            console.log("!data.status", data);
  
  
          }
        }
        if (error) {
          errorMsg(error.data.message);
          console.log("error", error);
          // navigate(`/${auth}/${user_token}`);
  
        }
        
      } catch (err) {
        console.log("err:",err);
      }finally{
        setTimeout(() => {
          setSaving(!!saving);
          setLoaderContent("")
          setLoading(false)
        }, 1000);
        
      }
    }





    // form.restart(newValues); replace new values form backend 
    // message.success('Saved success!')

  };


  const onUploadImage = async (blob) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(blob, options);
      // console.log("compressedFile::",compressedFile)
      const body = new FormData();
      body.append('image', compressedFile);

      // console.log(`originalFile Size ${blob.size / 1024 / 1024} MB`)
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

      const { data, error } = await uploadImage(body);
      if (data && data?.status) {
        successMsg(data?.message);
        return `${PHONE_TEMPLE_BASE_URL_IMG}${data.path}`
      }
      if (error) {
        errorMsg(error?.data?.message);
        console.log("Error::", error)

      }
    } catch (error) {
      console.log("catch-error::", error)
      // return await services.common.uploadByQiniu(data.path);
    }
  };


  const handleBack = () => {
    navigate(`/${auth}/${user_token}/${viewType}`);
  };


  return (
    <>
      {loading &&
        <LoadingComponentAntD content={loaderContent} />
      }
      {contextHolder}
      {siteInfo?.note && 
        <Alert message={siteInfo?.note} type="info" banner className="alert-left-align" />
      }
      <EmailEditorProvider
        data={myEmail}
        height={`${viewType==='true'?'calc(100vh -  93px)':'calc(100vh - 72px)'}`}
        autoComplete
        dashed={false}
        fontList={fontList}
        onUploadImage={onUploadImage}
        onSubmit={onSubmit}
        compact={true}
      // interactiveStyle={{
      //   hoverColor: '#78A349',
      //   selectedColor: '#1890ff',
      // }}
      >
        {({ values }, { submit }) => {
          return (
            <>
              <PageHeader
                onBack={viewType==='true' ? null :siteInfo?.email_signature?null: handleBack}
                title={props.updating ? myEmail?.subject : siteInfo?.instant_email ? "Instant Email":siteInfo?.email_signature?'New Signature Template': 'New Email Template'}
                // subTitle="This is a subtitle"
                extra={
                  <Stack alignment="center">
                    {/* <Button className='btn-header' onClick={() => onCopyHtml(values)}>
                      Gallary Image
                    </Button>
                    <Button className='btn-header' onClick={() => onCopyHtml(values)}>
                      Copy Html
                    </Button> */}
                    <button className='btn-header' onClick={() => defaultTemp()}>
                      Default Template
                    </button>
                    <button className='btn-header' onClick={() => resetTemplate()}>
                      Reset Template
                    </button>
                    {/* <button className='btn-header' onClick={onImportMjml}>
                      import Template
                    </button> */}
                    {/* <Button className='btn-header btn-header-primary'
                      type='primary'
                      onClick={() => submit()}
                      disabled={!!saving}
                    >
                      <span className='mr-2' style={{ marginRight: '5px' }}>{props.updating ? 'Update ' : 'Save '}</span> {saving && (<Spin indicator={antIcon} />)}
                    </Button> */}
                    <Button
                      className='btn-header btn-header-primary'
                      type="primary"
                      disabled={!!saving}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Confirm',
                          content: `Are you sure you want to ${props.updating ?  siteInfo?.instant_email?'send instant email':(props.updating && siteInfo?.email_signature)?'update signature':'update template' : siteInfo?.instant_email? 'send instant email':siteInfo?.email_signature?'save signature':'save template'}?`,
                          centered: true,
                          okText: props.updating ? siteInfo?.instant_email?'Send Email':'Update':siteInfo?.instant_email?'Send Email':'Save',
                          onOk() {
                            submit();
                          },
                          footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                              {/* <Button>Custom Button</Button> */}
                              <CancelBtn className="btn-header" />
                              <OkBtn type="primary" />
                            </>
                          ),
                        });
                      }}
                    >
                      <span className='mr-2' style={{ marginRight: '5px' }}>{props.updating ? siteInfo?.instant_email?"Send Email":'Update' : siteInfo?.instant_email?  'Send Email': 'Save'}</span> {saving && (<Spin indicator={antIcon} />)}
                    </Button>
                  </Stack>
                }

              />
                <StandardLayout
                  compact={!smallScene}
                  // compact={true}
                  showSourceCode={false}
                  // categories={defaultCategories}
                >
                  <EmailEditor />
                </StandardLayout>
                {/* SimpleLayout */}

            </>
          );
        }}
      </EmailEditorProvider>

    </>


  );
}

// BlockManager.registerBlocks({
//   emojiBlock, // Register the emoji block
// });

export default MailEditor